import {
  showSuccess,
  showError
} from "../notice";
import {
  hideModals
} from "../custom-modal";
import moment from 'moment';
import "jquery-validation";


$.validator.setDefaults({
  highlight: function (element) {
    $(element)
      .closest(".field")
      .addClass("field--error");
  },
  ignore: [],
  unhighlight: function (element) {
    $(element)
      .closest(".field")
      .removeClass("field--error");
  },
  errorElement: "p",
  errorClass: "messages",

  errorPlacement: function (error, element) {
    if (
      element.prop("type") === "checkbox" ||
      element.prop("type") === "radio"
    ) {
      error.insertAfter(element.parent());
    } else {
      error.insertAfter(element);
    }
  }
});

function cleanFields(formClass) {
  document.querySelectorAll(formClass).forEach(function (form) {
    form.reset();
  });
}

let sign_form = ".js-signin";
let choose_form = ".js-choose";
let signup_form = ".js-signup";

$(sign_form).validate({
  rules: {
    login: {
      required: true,
      email: true
    },
    password: {
      required: true,
      minlength: 8
    }
  },

  messages: {
    login: {
      required: "Введите e-mail",
      email: "Адрес должен быть вида mail@mail.ru"
    },
    password: {
      required: "Введите пароль",
      minlength: "Пароль минимум 8 символов"
    }
  },
  submitHandler: function (form) {
    let $form = $(form);
    let formData = $form.serializeArray();
    $.ajax({
      type: "POST",
      url: $form.attr("action"),
      data: formData,
      success: function (data) {
        if (data.success) {
          showSuccess("Успешная авторизация");
          cleanFields(sign_form);
          location.href = "/";
        } else {
          showError("Неверный логин или пароль!");
        }
      }
    });

    return false;
  }
});

$(choose_form).validate({
  rules: {
    login: {
      required: true,
      email: true
    }
  },

  messages: {
    login: {
      required: "Введите e-mail",
      email: "Адрес должен быть вида mail@mail.ru"
    }
  },
  submitHandler: function (form) {
    let $form = $(form);
    let formData = $form.serializeArray();
    $.ajax({
      type: "POST",
      url: $form.attr("action"),
      data: formData,
      success: function (data) {
        if (data.success) {
          showSuccess("Новый пароль отправлен на почту!");
          cleanFields(choose_form);
          location.href = "/";
        } else {
          showError("Пользователь с таким e-mail не зарегистрирован!");
        }
      }
    });

    return false;
  }
});

$(signup_form).validate({
  rules: {
    login: {
      required: true,
      email: true
    },
    user_name: {
      required: true
    },
    user_gender: {
      required: true
    },
    user_date_of_birth: {
      required: true
    },
    legal_name: {
      required: {
        depends: function () {
          let selected_date = document.getElementById('user_date_of_birth').value;
          let age = Math.floor(moment(new Date()).diff(moment(selected_date, "DD/MM/YYYY"), 'years', true));
          if (age < 14) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    area: {
      required: true
    },
    password: {
      required: true,
      minlength: 8
    },
    password_again: {
      required: true,
      equalTo: "#password"
    },
    agree: "required"
  },

  messages: {
    login: {
      required: "Введите e-mail",
      email: "Адрес должен быть вида mail@mail.ru"
    },
    user_name: {
      required: "Введите ФИО обучающегося"
    },
    user_gender: {
      required: "Выберите пол"
    },
    user_date_of_birth: {
      required: "Выберите дату рождения"
    },
    legal_name: {
      required: "Введите ФИО законного представителя"
    },
    area: {
      required: "Выберите муниципальный район"
    },
    password: {
      required: "Введите пароль",
      minlength: "Пароль минимум 8 символов"
    },
    password_again: {
      required: "Повторите пароль",
      equalTo: "Пароли не совпадают!"
    },
    agree: {
      required: "Подтвердите своё согласие с условиями"
    }
  },
  submitHandler: function (form) {
    let $form = $(form);
    let formData = $form.serializeArray();
    let username = $form.find('input[name="login"]').val();
    console.log(username);
    $.ajax({
      type: "POST",
      url: '/utility/check-user-exists',
      data: username,
      success: function (data) {
        console.log(data);
        if (data.exists) {
          showError("Пользователь с таким e-mail уже зарегистрирован!");
        } else {
          $.ajax({
            type: "POST",
            url: $form.attr("action"),
            data: formData,
            success: function (data) {
              if (data.success) {
                showSuccess("Успешная регистрация");
                cleanFields(choose_form);
                location.href = "/lichnij-kabinet";
              } else {
                showError("Пользователь с таким e-mail уже зарегистрирован!");
              }
            }
          });
        }
      }
    });



    return false;
  }
});