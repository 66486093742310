// якорь
function scrollTo(element, scrollBlock, offset) {
  //console.log(offset);
  let offset_top = offset || 0;

  if (element) {
    var elementClick = element.attr('data-scrollTo');
    var destination = $(elementClick).offset().top;
  } else {
    var destination = $(scrollBlock).offset().top;
  }
  if ($(window).width() > 768) {
    $('html').animate({
        scrollTop: destination - offset_top
      },
      700
    );
  } else {
    $('html').animate({
        scrollTop: destination - offset_top
      },
      0
    );
  }
  return false;
}
$('[data-scrollTo]').click(function () {

  if ($(this).is("[data-offset]")) {

    let offset = $(this).data('offset');

    scrollTo($(this), null, offset);

  } else {
    scrollTo($(this));
  }
  // scrollTo(null, '.order-main-steps');
});


// .js-active-checker

let act_wrapper = $('.js-active-checker');
let act_li = act_wrapper.find('li');
let act_offset = 60;

function activeChecker() {
  act_li.each(function () {
    let block_id = $(this).children('a').attr('data-scrollTo');
    let top_pos = $(window).scrollTop();
    if ($('body').find(block_id).length) {
      let block_height = $('div' + '' + block_id + '')[0].offsetHeight;
      let block_top = $('div' + '' + block_id + '')[0].offsetTop;
      let block_bottom = block_top + block_height;
      if (top_pos >= block_top - act_offset && top_pos < block_bottom) {
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }
    }



  });
}

if (act_wrapper.length) {
  $(document).ready(function () {
    activeChecker();
  });
  $(window).on('scroll', function () {
    activeChecker();
  });
}