// open search
$('.js-toggle-search').click(function () {
  // close mob menu
  $('.mobile-menu').removeClass('open');
  $('.menu-button').removeClass('open');
  $('body').removeClass('overflow-bg');
  //
  $(this).toggleClass('active');
  $('.header__search').toggleClass('open');
  setTimeout(() => {
    $('.header__search > input').focus();
  }, 100);
})
//-