  import 'slick-slider';
  $(document).ready(function () {
    $('.block-slider').slick({
      adaptiveHeight: true
    });
    $('.block-carousel').slick({
      slidesToShow: 3,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }, ]
    });
  });