import '../lib/resizeSensor';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar';

if ($('.js-sticky').length) {
  let sidebar = new StickySidebar('.js-sticky', {
    topSpacing: 0,
    responsiveWidth: true,
    resizeSensor: true,
    minWidth: 1024
  });

  var stickyInterval;
  stickyInterval = setInterval(function () {
    if (sidebar) {
      sidebar.updateSticky();
    }
  }, 500);

  // navigation podmenu
  $('.sidebar-navigation ul li').each(function () {

    if ($(this).find('ul').length) {
      $(this).addClass('has-podmenu');
      $(this).children('a').click(function (e) {
        if (!$(this).parent().hasClass('active')) {
          e.preventDefault();
          // удалить у всех active
          $('.sidebar-navigation ul li ul').slideUp();
          $('.sidebar-navigation ul li').removeClass('active');
          // добавить active
          $(this).parent().find('ul').slideDown();
          $(this).parent().toggleClass('active');
          sidebar.updateSticky();

        } else {
          e.preventDefault();
          $(this).parent().find('ul').slideUp();
          $(this).parent().toggleClass('active');
          sidebar.updateSticky();

        }
      });

    }

  });
}