// vendors
import 'lazyload/lazyload.min';
// import './lib/jquery-ui.min';

// polyfills
import 'svgxuse';
import 'nodelist-foreach-polyfill';

// components
import './components/ieDetect';
import './components/supports';
import './components/modals';
import './components/custom-modal';
import './components/sliders';
import './components/scrollTo';
import './components/search-header';
import './components/sidebar-navigation';
import './components/rating';
import './components/mobile-menu';
import './components/sticky';
import './components/youtube-thumbnail';
import './components/masks';
import './components/fields';
import './components/datepicker';
import './components/forms';
import './components/visuallimpaired';

//
import './components/validation';

import {
  showSuccess,
  showError
} from './components/notice';


$(document).ready(function () {
  $('.loader').fadeOut();
  $('img[data-src]').lazyload();


  // Переход по ссылке в фильтрах вебинаров из value 
  $('.js-value-link .filter-button').on('click', function () {
    let link = $(this).children('input').val();
    location.href = link

  })

});