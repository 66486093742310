 // mobile menu
 let mob_menu = '<div class="mobile-menu"><div class="mobile-menu__buttons"></div><nav class="mobile-menu__nav"></nav></div>'
 let header_buttons = $('.header__buttons a').clone();
 let header_menu = $('.header__nav ul').clone();
 let authorized = $('.authorized').clone();
 let contacts = $('.header .contacts-module').clone();


 $('body').append(mob_menu);
 $('.mobile-menu__buttons').append(contacts);
 //$('.mobile-menu__buttons').append(authorized);
 $('.mobile-menu__nav').append(header_menu);
 //-

 function menuPosition() {
   let header_height = $('.header').innerHeight();
   $('.mobile-menu').css({
     'top': header_height,
     'height': `calc(100vh - ${header_height}px)`
   });
 }
 document.addEventListener("DOMContentLoaded", () => {

   setTimeout(() => {
     menuPosition();
   }, 1000);
 });
 $(window).on('resize', function () {
   setTimeout(() => {
     menuPosition();
   }, 1000);
 });


 $('.menu-button').click(function () {
   // close search
   $('.header__search').removeClass('open');
   $('.js-toggle-search').removeClass('open');
   //

   $('body').toggleClass('overflow-bg');
   $(this).toggleClass('open');
   $('.mobile-menu').toggleClass('open');
 });
 //-