  // js modal

  $('body').delegate('.js-custom-modal', 'click', function (e) {
    e.preventDefault();
    let currentWindow = $(this).attr('data-modal-id');
    $('.modal').removeClass('active');
    $('div.modal[id="' + currentWindow + '"]').addClass('active');
    //$('body').addClass('overflow');
  });

  $('body').delegate('.js-hide-modal', 'click', function (e) {
    e.preventDefault();
    hideModals();
    //$('body').removeClass('overflow');
  });

  export function hideModals() {
    $('.modal').removeClass('active');
  }
  $(document).ready(function () {

    setTimeout(() => {
      $('.modal').addClass('init');
    }, 500);

  });

  // $(document).mouseup(function(e) {
  //   var div = $('.modal__block');
  //   if (!div.is(e.target) &&
  //     div.has(e.target).length === 0) {
  //     $('.modal').removeClass('active');
  //     $('body').removeClass('overflow-bg');
  //   }
  // });