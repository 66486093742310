import {
    showSuccess,
    showError
} from "./notice";
import {
    hideModals
} from "./custom-modal";


$("body")
    .on("submit", ".js-ajax-form", function (e) {
        let $form = $(this);
        let formData = $form.serializeArray();

        $.ajax({
            type: "POST",
            url: $form.attr('action'),
            data: formData,
            success: function (data) {
                showSuccess('Успешно отправлено');
                document.querySelectorAll('.js-ajax-form').forEach(function (form) {
                    form.reset();
                });
                hideModals();
            }
        });


        return false;
    });