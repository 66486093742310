  // rating
  let rating = $('.rating');
  rating.each(function () {
    let span = "<span></span>";
    let stars_count = 3;
    let data_stars = $(this).data('stars');
    for (let i = 0; i < stars_count; i++) {
      $(this).append(span);
    }
    $(this).find('span:lt(' + data_stars + ')').addClass('active');
  })
  //-