  // sidebar-navigation mobile
  let s_nav = $('.sidebar-navigation');
  let toggle_btn_html = "<span class='sidebar-nav-toggle'>Разделы</span>";

  function addToggleBtn() {
    if ($(window).outerWidth() < 1024 && $('.sidebar-nav-toggle').length === 0) {
      s_nav.hide();
      s_nav.parents('.page__sidebar').prepend(toggle_btn_html);
    } else if ($(window).outerWidth() >= 1024) {
      $('.sidebar-nav-toggle').remove();
      s_nav.show();
    }
  }

  addToggleBtn();

  $(window).on('resize', function () {
    addToggleBtn()
  });

  s_nav.parents('.page__sidebar').delegate('span.sidebar-nav-toggle', 'click', function () {
    $('.sidebar-navigation').slideToggle();
    $(this).toggleClass('active');
  });
  //-